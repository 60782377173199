<template>
  <div class="main-content" id="content">
    <h3 class="marginBottom20">协议设置</h3>
    <!-- <el-card style="padding-left: 50px"> -->
    <el-form
      ref="form"
      :model="form"
      label-position="left"
      label-width="80px"
      :rules="rules"
    >
      <el-form-item label="用户条款" :span="8" prop="agreement">
        <FormEditor
          :details="form.agreement"
          @editoChange="editoChange"
          :disabled="disabled"
        />
      </el-form-item>
      <el-form-item label="隐私声明" :span="8" prop="privacyPolicy">
        <FormEditor
          :details="form.privacyPolicy"
          @editoChange="privacyPolicyChange"
          :disabled="disabled"
        />
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="submit">确认</el-button>
      </el-form-item>
    </el-form>
    <!-- </el-card> -->
  </div>
</template>

<script>
import { agreementUpdate, agreementView } from "@/api/system/agreement";
import FormEditor from "@/components/formComponents/formEditor";
import dayjs from "dayjs";
import FormFileUpload from "@/components/formComponents/formFileUpload";

export default {
  name: "create",
  components: {
    FormEditor,
    FormFileUpload,
  },
  data() {
    return {
      classifyList: [],
      id: this.$route.query.id,
      disabled: this.$route.query.type === "view",
      type: this.$route.query.type,
      form: {
        agreement: "",
        privacyPolicy: "",
      },
      //校验规则
      rules: {
        agreement: [
          {
            required: true,
            message: "请输入用户协议",
            trigger: "blur",
          },
          {
            validator(_, value) {
              if (value) {
                if (value === "<p><br></p>") {
                  return Promise.reject(new Error("请输入内容"));
                }
                if (value.length > 65535) {
                  return Promise.reject(new Error("内容超出最大长度限制"));
                }
              }
              return Promise.resolve(true);
            },
          },
        ],
        privacyPolicy: [
          {
            required: true,
            message: "请输入隐私政策",
            trigger: "blur",
          },
          {
            validator(_, value) {
              if (value) {
                if (value === "<p><br></p>") {
                  return Promise.reject(new Error("请输入内容"));
                }
                if (value.length > 65535) {
                  return Promise.reject(new Error("内容超出最大长度限制"));
                }
              }
              return Promise.resolve(true);
            },
          },
        ],
      },
      loadingSubmit: false,
    };
  },
  mounted() {
    this.getView();
  },
  methods: {
    async getView() {
      const { data } = await agreementView(this.id);
      this.form = {
        ...data,
      };
    },

    async submit() {
      if (this.loadingSubmit) {
        return;
      }
      let newVal = {
        ...this.form,
      };
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loadingSubmit = true;
          agreementUpdate({
            ...newVal,
          })
            .then((res) => {
              // 获取数据成功后的其他操作
              if (res.code == 200) {
                this.loadingSubmit = false;
                this.$message.success("提交成功");
                this.getView();
              } else {
                this.loadingSubmit = false;
                this.$message.error(res.msg);
              }
            })
            .catch((err) => {
              // loading.close();
              // this.$message.error(err.msg);
              this.loadingSubmit = false;
            });
        }
      });
    },
    //富文本框变化替换值
    editoChange(e) {
      this.form.agreement = e;
    },
    //隐私政策
    privacyPolicyChange(e) {
      this.form.privacyPolicy = e;
    },
    //文件上传
    upload(e, name) {
      if (Array.isArray(e)) {
        this.form.firmwareUrl = [...e];
      } else {
        this.form.firmwareUrl = [...this.form.firmwareUrl, e];
      }
      this.form.firmwareName = name;
    },
    //图片上传
    uploadImg(e) {
      if (Array.isArray(e)) {
        this.form.mainImage = [...e];
      } else {
        this.form.mainImage = [...this.form.mainImage, e];
      }
    },
  },
};
</script>

<style scoped>
.main-content {
  padding-left: 30px;
  padding-right: 40%;
}
</style>
